import { useAppSelector } from 'state/hooks'
import {
  closeForm,
  selectBuiltFormTabs,
  selectFormTab,
  formAction,
  selectInputVal,
  setInput,
  DEPOSIT_TAB,
  WITHDRAW_TAB,
  BORROW_TAB,
  REPAY_TAB,
  selectTxReviewOpen,
  openTxReview,
  closeTxReview,
  selectActiveIncentiveData
} from 'state/slices/ui/form'
import Tabs from 'components/common/Tabs'
import InfoPoint from '../common/InfoPoint'
import ParamList from './ParamList'
import CloseButton from 'components/common/CloseButton'
import { MetaBroker, FormattedPositions } from 'state/mock'
import { isNil } from 'ramda'
import EmptyForm from './EmptyForm'
import {
  detrmineHealthColor,
  formatHealthFactor,
  formatPercentage,
  prettyTokenBal
} from 'toolbox/format'
import { BORROW, COLLATERAL, selectDashTab } from 'state/slices/ui/dash'
import {
  estimateNewAvailable,
  estimateNewBorrowed,
  calcNewUtil,
  calcUtil,
  calcLendRate,
  floorAndFixToEightDecimals
} from 'toolbox/calc'
import {
  calcBorrowData,
  calcHealthFactor,
  calcSupplyData,
  isRedZone,
  isYellowZone,
  selectHealthFactor,
  selectMaxBorrowForAsset,
  selectPortfolio
} from 'state/slices/user/portfolio'
import { selectPortfolioBusy } from 'state/slices/user/portfolio'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import {
  getWalletBalanceByName,
  selectLoadedWalletBalances
} from 'state/slices/user/walletBalances'
import { selectUnderlyingNamesFromBrokers } from 'state/slices/app/brokers'
import { SDK_CONTEXT } from 'state/context'
import {
  buildCurrentPortfolioBasicState,
  buildNextPortfolioState,
  getNextPosition,
  getNextWalletBalance,
  shouldGetRiskEval
} from 'state/thunks/doTx'
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { selectTxStatus } from 'state/slices/ui/transaction'
import { ParamProps } from './Param'
import { TxFormReview } from './TxFormReview'
import InfoBox, { InfoBoxProps } from '../common/InfoBox'
import { InputContainer } from './InputContainer'
import debounce from 'lodash/debounce'
import { isEqual } from 'lodash'
import { fetchSimulatedPortfolio } from 'toolbox/util'

export interface TxFormProps {
  broker: MetaBroker
  isLoadedUser: boolean
  positions: FormattedPositions
}

function TxForm({ broker, isLoadedUser, positions }: TxFormProps) {
  //state and selectors
  const tabs = useAppSelector(selectBuiltFormTabs)
  const selectedTab = useAppSelector(selectFormTab)
  const dashTab = useAppSelector(selectDashTab)
  const activeIncentiveData = useAppSelector(selectActiveIncentiveData)
  const { incentiveTicker, supplyRewardRate, borrowRewardRate } = activeIncentiveData
  const inputVal = useAppSelector(selectInputVal)
  const amount = parseFloat(inputVal)
  const isUserLoadBusy = useAppSelector(selectPortfolioBusy)
  const healthFactor = useAppSelector(selectHealthFactor)
  const txReviewOpen = useAppSelector(selectTxReviewOpen)
  const balances = useAppSelector(selectLoadedWalletBalances)
  const { account, signAndSubmitTransaction } = useWallet()
  const basicPortfolio = useAppSelector(selectPortfolio)
  const hasInput = parseFloat(inputVal) > 0
  const brokerNames = useAppSelector(selectUnderlyingNamesFromBrokers)
  const isTxPending = useAppSelector(selectTxStatus) === 'pending'
  const parsedInput = parseFloat(inputVal) || 0
  const maxBorrowsForAsset = useAppSelector((s) =>
    selectMaxBorrowForAsset(s, broker?.loanNote?.name)
  )

  const [simulationState, setSimulationState] = useState({
    isLoading: false,
    healthFactor: 0,
    isYellow: false,
    isRed: false,
    isHealthy: false,
    isLTVWarning: false,
    ltv: 0
  })

  //portfolio builders for simulator
  const currentPortfolioBasic = useMemo(
    () => buildCurrentPortfolioBasicState(basicPortfolio),
    [basicPortfolio]
  )

  const abortControllerRef = useRef<AbortController | null>(null)

  const prevInputRef = useRef(inputVal)
  const prevBrokerRef = useRef(broker)
  const prevSelectedTabRef = useRef(selectedTab)

  const nextPortfolio = useMemo(
    () => buildNextPortfolioState(currentPortfolioBasic, selectedTab, amount, broker),
    [currentPortfolioBasic, selectedTab, amount, broker]
  )

  const nextPosition = useMemo(
    () => getNextPosition(nextPortfolio, selectedTab, broker),
    [nextPortfolio, selectedTab, broker]
  )

  const token = broker?.tokenMeta
  const chain = broker?.chainMeta
  const price = broker?.underlyingAsset?.price
  const inputDollars = parsedInput > 0 ? parsedInput * price : 0
  const utilization = calcUtil(broker)
  const supplyData = calcSupplyData(positions, broker)
  const borrowData = calcBorrowData(positions, broker)
  const brokerBorrowed = broker?.scaledTotalBorrowedUnderlying
  const totalAvailable = broker?.scaledAvailableLiquidityUnderlying
  const balance = getWalletBalanceByName(balances, token.name)
  const isBorrowTab = selectedTab === BORROW_TAB

  //next values
  const nextAvail = estimateNewAvailable(broker, inputVal, selectedTab)
  const nextTotalBorrowed = estimateNewBorrowed(brokerBorrowed, inputVal, selectedTab)
  const nextTotalSupplied = nextAvail + nextTotalBorrowed
  const nextUtil = calcNewUtil(nextTotalBorrowed, nextTotalSupplied)
  const nextBorrowInterestRate = SDK_CONTEXT.superSdk.getInterestRate(
    nextUtil,
    broker.interestRateCurve
  )
  const stabilityFeeRate = 0.0015 // 0.15%
  const nextBorrowAPR = nextBorrowInterestRate + stabilityFeeRate
  // None of the stability fee is passed to the lender
  const nextSupplyAPR = calcLendRate(
    nextBorrowInterestRate,
    broker?.interestFeeRate,
    broker?.utilization
  )
  const brokerTotal = brokerBorrowed + totalAvailable
  const nextWalletBalance = getNextWalletBalance(selectedTab, amount, balance)

  //broker maximums
  const maxSupplyBroker = Number(broker?.maxDepositScaled)
  const maxBorrowBrokerLimit = Number(broker?.maxBorrowScaled)

  //max deposit logic
  const overBrokerDepositLimit = nextTotalSupplied > maxSupplyBroker
  const depositDiffToBrokerLimit = maxSupplyBroker - brokerTotal
  const depositDiffToBrokerLimitNegative = depositDiffToBrokerLimit <= 0
  const poolIsFull = selectedTab === DEPOSIT_TAB && depositDiffToBrokerLimitNegative
  const walletBalanceOrDepositDiffLess =
    balance < depositDiffToBrokerLimit ? balance : depositDiffToBrokerLimit
  const walletBalanceOrDepositDiffLessShaved =
    balance < depositDiffToBrokerLimit
      ? floorAndFixToEightDecimals(balance)
      : depositDiffToBrokerLimit

  //max withdraw logic
  const suppOrAvailLower = supplyData.exchangeRateBalance < totalAvailable
  const maxWithdrawUser = suppOrAvailLower ? supplyData.exchangeRateBalance : totalAvailable
  const maxWithdrawUserShaved = suppOrAvailLower
    ? floorAndFixToEightDecimals(supplyData.exchangeRateBalance)
    : floorAndFixToEightDecimals(totalAvailable)

  //max repay logic
  const maxRepayPortfolioBalance = borrowData.exchangeRateBalance
  const maxRepayPortLower = maxRepayPortfolioBalance < balance
  const maxRepayPortOrWalletBalance = maxRepayPortLower ? maxRepayPortfolioBalance : balance

  //broker limit max borrow logic
  const overBrokerBorrowLimit = nextTotalBorrowed > maxBorrowBrokerLimit
  const borrowDiffToBrokerLimit = maxBorrowBrokerLimit - brokerBorrowed
  const borrowDiffToBrokerLimitNegative = borrowDiffToBrokerLimit <= 0
  const borrowsAreFull = selectedTab === BORROW_TAB && borrowDiffToBrokerLimitNegative
  const totalAvailableLessThanBrokerLimit = totalAvailable < borrowDiffToBrokerLimit
  const borrowAvailOrLimit = totalAvailableLessThanBrokerLimit
    ? totalAvailable
    : borrowDiffToBrokerLimit
  const { maxBorrowUnderlying } = maxBorrowsForAsset

  const nextBorrowExceedsAvailable = nextTotalBorrowed > totalAvailable

  const maxTxValueUnderlying = function setMaxValue() {
    switch (selectedTab) {
      case DEPOSIT_TAB:
        return walletBalanceOrDepositDiffLess > 0 ? walletBalanceOrDepositDiffLess : 0
      case WITHDRAW_TAB:
        return maxWithdrawUser
      case BORROW_TAB:
        return borrowAvailOrLimit
      case REPAY_TAB:
        return maxRepayPortOrWalletBalance
    }
  }

  //max value of token to transact logic
  const maxTxValueUnderlyingShaved = function setMaxValue() {
    switch (selectedTab) {
      case DEPOSIT_TAB:
        return walletBalanceOrDepositDiffLessShaved > 0 ? walletBalanceOrDepositDiffLessShaved : 0
      case WITHDRAW_TAB:
        return maxWithdrawUserShaved
      case BORROW_TAB:
        return floorAndFixToEightDecimals(borrowAvailOrLimit)
      case REPAY_TAB:
        return floorAndFixToEightDecimals(maxRepayPortOrWalletBalance)
    }
  }

  //calc if over a limit
  const over = parsedInput > maxTxValueUnderlying()
  const blockCalcs = nextPosition < 0 || nextWalletBalance < 0 || over

  const fetchSimRiskData = useCallback(async () => {
    if (
      !isLoadedUser ||
      !hasInput ||
      !shouldGetRiskEval(selectedTab, nextPortfolio) ||
      blockCalcs
    ) {
      setSimulationState((prevState) => ({
        ...prevState,
        isLoading: false,
        isYellow: false,
        isRed: false,
        isLTVWarning: false,
        ltv: 0
      }))
      return
    }

    setSimulationState((prevState) => ({ ...prevState, isLoading: true }))

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    abortControllerRef.current = new AbortController()

    try {
      const response = await fetchSimulatedPortfolio({
        portfolio: nextPortfolio,
        apiUrl: process.env.REACT_APP_SUPER_JSON_API_URL,
        signal: abortControllerRef.current.signal
      })

      const simFactor = calcHealthFactor(response)
      const simYellow = isYellowZone(simFactor)
      const simRed = isRedZone(simFactor)
      const healthy = simFactor > 1.0
      const ltvWarn = response.ltv > 0.95

      setSimulationState({
        isLoading: false,
        healthFactor: simFactor,
        isYellow: simYellow,
        isRed: simRed,
        isHealthy: healthy,
        isLTVWarning: healthy && ltvWarn,
        ltv: ltvWarn ? response.ltv : 0
      })
    } catch (e) {
      if (e.name !== 'AbortError') {
        setSimulationState({
          isLoading: false,
          healthFactor: 0,
          isYellow: false,
          isRed: true,
          isHealthy: false,
          isLTVWarning: false,
          ltv: 0
        })
      }
    }
  }, [isLoadedUser, hasInput, selectedTab, nextPortfolio, blockCalcs])

  const debouncedFetchSimRiskData = useMemo(() => debounce(fetchSimRiskData, 0), [fetchSimRiskData])

  useEffect(() => {
    const inputChanged = inputVal !== prevInputRef.current
    const brokerChanged = !isEqual(broker, prevBrokerRef.current)
    const selectedTabChanged = selectedTab !== prevSelectedTabRef.current

    if (inputChanged || brokerChanged || selectedTabChanged) {
      console.log('Triggering sim data fetch')
      debouncedFetchSimRiskData()

      prevInputRef.current = inputVal
      prevBrokerRef.current = broker
      prevSelectedTabRef.current = selectedTab
    }

    return () => {
      debouncedFetchSimRiskData.cancel()
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [debouncedFetchSimRiskData, inputVal, broker, selectedTab])

  useEffect(() => {
    if (!broker || !hasInput) {
      setSimulationState((prevState) => ({
        ...prevState,
        isYellow: false,
        isRed: false,
        isHealthy: false,
        isLTVWarning: false,
        ltv: 0
      }))
    }
  }, [broker, hasInput])

  // const blockCalcs = nextWalletBalance < 0 || over || simulationState.isLoading

  //add extra logic to differentiate unhealthy from over limits but still disable
  const overLimit = () => {
    let isOver = false
    switch (selectedTab) {
      case DEPOSIT_TAB:
        isOver = over || poolIsFull || overBrokerDepositLimit
        break
      case WITHDRAW_TAB:
        isOver = over || simulationState.isRed || simulationState.isLTVWarning
        break
      case BORROW_TAB:
        isOver =
          over ||
          simulationState.isRed ||
          borrowsAreFull ||
          overBrokerBorrowLimit ||
          simulationState.isLTVWarning
        break
      case REPAY_TAB:
        isOver = over
        break
    }
    return isOver
  }

  //risk coloration classes
  const isYellowZoneStyles =
    !simulationState.isLoading &&
    simulationState.isYellow &&
    !over &&
    !simulationState.isLTVWarning &&
    hasInput
      ? 'yellow-zone'
      : ''
  const isRedZoneStyles =
    !simulationState.isLoading &&
    simulationState.isRed &&
    !over &&
    !simulationState.isLTVWarning &&
    hasInput
      ? 'red-zone'
      : ''
  const isGreenZoneStyles = !simulationState.isRed && !simulationState.isYellow && !over && hasInput
  const currentHealthYellow = isYellowZone(healthFactor)
  const currentHealthRed = isRedZone(healthFactor)
  const currentHealthGreen = !currentHealthRed && !currentHealthYellow && !over

  //over lmit messaging logic
  function overLimitMessage() {
    switch (selectedTab) {
      case DEPOSIT_TAB:
        return poolIsFull
          ? 'Pool is full'
          : overBrokerDepositLimit
          ? `Exceeds ${token.ticker} deposit limit`
          : 'Exceeds wallet balance'
      case WITHDRAW_TAB:
        return over
          ? suppOrAvailLower
            ? 'Exceeds supplied balance'
            : 'Exceeds available liquidity'
          : !simulationState.isHealthy
          ? 'User would become unhealthy'
          : 'Nearing liquidation threshold'
      case BORROW_TAB:
        return borrowsAreFull
          ? 'Broker borrow limit reached'
          : overBrokerBorrowLimit
          ? `Exceeds ${token.ticker} borrow limit`
          : nextBorrowExceedsAvailable
          ? 'Exceeds available liquidity'
          : simulationState.isRed
          ? !simulationState.isHealthy
            ? 'User would become unhealthy'
            : 'Nearing liquidation threshold'
          : 'Exceeds max safe borrow'

      case REPAY_TAB:
        return maxRepayPortLower ? 'Exceeds portfolio debt' : 'Exceeds wallet balance'
    }
  }

  function overLimitInfoBox() {
    const ticker = token.ticker
    switch (selectedTab) {
      case DEPOSIT_TAB:
        if (poolIsFull)
          return `${ticker} pool is full. Pool limits are set by the broker and can be adjusted. They are in place to protect the health of the pool and the safety of the users.`
        if (overBrokerDepositLimit)
          return `Amount exceeds max deposit value set for ${ticker} by broker`
        return `Amount exceeds wallet balance of ${ticker}`
      case WITHDRAW_TAB:
        return suppOrAvailLower
          ? `Amount exceeds supplied balance of ${ticker}`
          : `Amount exceeds available liquidity for ${ticker} in broker`
      case BORROW_TAB:
        if (borrowsAreFull)
          return `Borrowing for this broker has reached a maximum limit set by the broker.  This limit can be adjusted, and is in place to protect the health of the pool and the safety of the users.`
        if (overBrokerBorrowLimit)
          return `Amount exceeds max borrow value set for ${ticker} by broker`
        if (nextBorrowExceedsAvailable)
          return `Amount exceeds available liquidity for ${ticker} in broker`
        return ''
      case REPAY_TAB:
        return maxRepayPortLower
          ? `Amount exceeds portfolio ${ticker} debt balance to repay`
          : `Amount exceeds ${ticker} wallet balance`
      default:
        return ''
    }
  }

  const infoBoxText =
    overLimit() && simulationState.isLTVWarning
      ? `Amount results in ${formatPercentage(
          simulationState.ltv
        )} LTV, exceeding 95% max LTV limit`
      : overLimit()
      ? overLimitInfoBox()
      : null

  const maxParam =
    selectedTab === BORROW_TAB || selectedTab === REPAY_TAB
      ? null
      : {
          label: 'Pool max limit',
          value: prettyTokenBal(maxSupplyBroker),
          tooltip: 'Maximum liquidity that can be supplied to the pool'
        }

  //broker params and projections
  const brokerStats = [
    {
      label: 'Total Available In Broker',
      value: prettyTokenBal(totalAvailable),
      tooltip: `Liquidity available for borrowing or withdrawal in tokens`,
      next: !blockCalcs && hasInput ? prettyTokenBal(nextAvail) : null
    },
    {
      label: 'Total Loaned By Broker',
      value: prettyTokenBal(brokerBorrowed),
      tooltip: `Liquidity currently loaned to borrowers in tokens`,
      next: !blockCalcs && hasInput ? prettyTokenBal(nextTotalBorrowed) : null
    },
    {
      label: 'Total Supplied In Broker',
      value: prettyTokenBal(brokerTotal),
      tooltip: 'Liquidity supplied in tokens',
      next: !blockCalcs && hasInput ? prettyTokenBal(nextTotalSupplied) : null
    },
    maxParam,
    {
      label: 'Utilization',
      value: formatPercentage(utilization),
      tooltip: 'Ratio of debt / collateral.  High utilization increases interest',
      next: !blockCalcs && hasInput ? formatPercentage(nextUtil) : null
    }
  ]

  //health factor display logic
  const healthDisplay = formatHealthFactor(healthFactor)
  const simHealthDisplay = formatHealthFactor(simulationState.healthFactor)

  //param builders
  const healthFactorParam = {
    label: 'Health factor',
    value: !isLoadedUser && isUserLoadBusy ? '--' : healthDisplay,
    tooltip: 'Health simulation of next position required to be 1.2x or higher for user safety',
    next: blockCalcs || !hasInput ? null : simulationState.isLoading ? '--' : simHealthDisplay,
    currentColor: detrmineHealthColor(currentHealthGreen, currentHealthRed, currentHealthYellow),
    simColor: detrmineHealthColor(
      isGreenZoneStyles,
      simulationState.isRed,
      simulationState.isYellow
    )
  }

  const userSuppliedParam = {
    label: 'Supplied',
    value: prettyTokenBal(supplyData.exchangeRateBalance) + ' ' + token?.ticker,
    next: !blockCalcs && hasInput ? prettyTokenBal(nextPosition) + ' ' + token?.ticker : null,
    tooltip: 'Current collateral balance in tokens.  Max amount user can withdraw unless unhealthy'
  }

  const supplyAPRParam = {
    label: 'Supply APR',
    value: formatPercentage(supplyData.supplyAPR),
    tooltip: 'Rate of earning interest on deposits over one year',
    next: !blockCalcs && hasInput && nextSupplyAPR < 100 ? formatPercentage(nextSupplyAPR) : null
  }

  const userBorrowedParam = {
    label: 'Borrowed',
    value: prettyTokenBal(borrowData.exchangeRateBalance) + ' ' + token?.ticker,
    next: !blockCalcs && hasInput ? prettyTokenBal(nextPosition) + ' ' + token?.ticker : null,
    tooltip: 'Current debt balance in tokens.  Max amount user can repay'
  }

  const borrowAPRParam = {
    label: 'Borrow APR',
    value: formatPercentage(borrowData.borrowAPR),
    tooltip: 'High utilization = more demand, increasing borrower interest',
    next: !blockCalcs && hasInput ? formatPercentage(nextBorrowAPR) : null
  }

  const walletBalanceParam = {
    label: 'Wallet balance',
    value: `${prettyTokenBal(balance)} ${token?.ticker}`,
    next:
      !blockCalcs && hasInput && nextWalletBalance > 0
        ? `${prettyTokenBal(nextWalletBalance)} ${token?.ticker}`
        : null
  }

  const supplyIncentiveParam = {
    label: 'Supply Reward APR',
    value: `+ ${formatPercentage(supplyRewardRate)}`,
    tooltip: `Additional reward APR in ${incentiveTicker}, increasing interest earned`,
    next: null
  }

  const borrowIncentiveParam = {
    label: 'Borrow Reward APR',
    value: `- ${formatPercentage(borrowRewardRate)}`,
    tooltip: 'Additional reward APR in APT, offsetting interest paid',
    next: null
  }

  //params for modals
  const depositParams =
    supplyRewardRate > 0
      ? [healthFactorParam, userSuppliedParam, supplyAPRParam, supplyIncentiveParam]
      : [healthFactorParam, userSuppliedParam, supplyAPRParam]
  const borrowParams =
    borrowRewardRate > 0
      ? [healthFactorParam, userBorrowedParam, borrowAPRParam, borrowIncentiveParam]
      : [healthFactorParam, userBorrowedParam, borrowAPRParam]
  const belowParams = [walletBalanceParam]

  function formParams(): ParamProps[] {
    switch (dashTab) {
      case COLLATERAL:
        return depositParams
      case BORROW:
        return borrowParams
    }
  }

  //form actions and button functions
  const action = () => {
    if (txReviewOpen) {
      formAction(
        selectedTab,
        broker,
        amount,
        account?.address as string,
        positions,
        signAndSubmitTransaction,
        brokerNames
      )
    } else {
      openTxReview()
    }
  }

  const buttonText = simulationState.isLoading
    ? 'Simulating health factor'
    : overLimit()
    ? simulationState.isLTVWarning
      ? 'LTV would exceed 95%'
      : overLimitMessage()
    : txReviewOpen
    ? `${selectedTab} ${token?.ticker}`
    : 'Review'

  //final safety check
  const zeroInput = parsedInput <= 0
  const disabledForm =
    !isLoadedUser ||
    overLimit() ||
    zeroInput ||
    simulationState.isLoading ||
    !simulationState.isHealthy

  const disabledReview = disabledForm || isTxPending
  const txReviewProps = {
    closeForm,
    selectedTab,
    account,
    inputVal,
    token,
    formParams,
    moreParams: brokerStats,
    closeTxReview,
    action,
    disabled: disabledReview,
    buttonText
  }

  //short circuit to TX review (step 2)
  if (txReviewOpen) {
    return <TxFormReview {...txReviewProps} />
  }

  const blueInfoBoxProps: InfoBoxProps = {
    color: 'blue',
    showBox: (over && infoBoxText) || simulationState.isLTVWarning ? true : false,
    text: infoBoxText,
    fullWidth: true
  }

  const orangeInfoBoxProps: InfoBoxProps = {
    color: 'orange',
    showBox: isYellowZoneStyles ? true : false,
    text: 'Your position is nearing the liquidation threshold.',
    fullWidth: true
  }

  const redInfoBoxProps: InfoBoxProps = {
    color: 'red',
    showBox: isRedZoneStyles ? true : false,
    text: !simulationState.isHealthy
      ? 'Your portfolio would become unhealthy.'
      : 'Your position is nearing the liquidation threshold.',
    fullWidth: true
  }

  const actionButtonProps = {
    className: 'sp-btn primary large full-w send-btn',
    onClick: action,
    disabled: disabledForm
  }

  function setMax() {
    if (isBorrowTab) {
      const maxUnderlying = maxTxValueUnderlyingShaved()
      const maxBorrowLessAvailable = maxBorrowUnderlying < maxUnderlying
      if (maxBorrowLessAvailable) {
        setInput(floorAndFixToEightDecimals(maxBorrowUnderlying).toString())
      } else {
        setInput(maxUnderlying.toString())
      }
    } else {
      setInput(maxTxValueUnderlyingShaved().toString())
    }
  }

  const inputProps = {
    token,
    chain,
    inputVal,
    setInput,
    inputDollars,
    setMax,
    hideMax: false
  }

  //OG form component
  return (
    <div className={`tx-form ${isYellowZoneStyles} ${isRedZoneStyles} `}>
      <CloseButton cb={closeForm} />
      <div className="title">
        <div className="is-flex">
          <InfoPoint value={`${selectedTab} ${token.ticker}`} sub={''} />
        </div>
      </div>
      <Tabs tabs={tabs} variant="basic" mods={'full-width'} />
      <InputContainer {...inputProps} />
      <InfoBox {...blueInfoBoxProps} />
      <InfoBox {...orangeInfoBoxProps} />
      <InfoBox {...redInfoBoxProps} />
      <ParamList params={formParams()} more={brokerStats} />
      <button {...actionButtonProps}>{buttonText}</button>
      <ParamList params={belowParams} />
    </div>
  )
}

export default React.memo(TxForm, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
})
