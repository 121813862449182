import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { formatPublicKeyDisplay } from 'toolbox/format'
import copy from 'assets/icons/copy.svg'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export interface WalletDisconnectProps {
  disconnect: () => void
  closeModal: () => void
}

function WalletDisconnect({ disconnect, closeModal }: WalletDisconnectProps) {
  const { account } = useWallet()
  const address = account?.address
  const modalRef = useRef(null)

  const handleButtonClick = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText(address as string)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeModal])

  const urlQueryParams = new URLSearchParams(useLocation().search)
  const viewportfolio = urlQueryParams.get('viewportfolio')

  if (viewportfolio) {
    return (
      <>
        <div className="disconnect-modal" ref={modalRef} onClick={(e) => e.stopPropagation()}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <div className="add-bar">
              <p>{formatPublicKeyDisplay(address)}</p>
              <button onClick={handleButtonClick}>
                <img src={copy} alt="copy" />
              </button>
            </div>
            <hr className="hr" />
            <div className="disconnect">
              <button className="sp-btn full-w tertiary" disabled={true}>
                <p className="pri">Read-only mode</p>
              </button>
            </div>
            <div className="links">
              <a
                href="https://www.concordia.systems/"
                className="a-link grey"
                rel="noopener noreferrer"
                target="_blank">
                Concordia
              </a>
              <a
                href="https://www.superposition.finance/"
                className="a-link grey"
                rel="noopener noreferrer"
                target="_blank">
                Superposition
              </a>
            </div>
          </div>
        </div>
        <div className="close-helper" onClick={closeModal} />
      </>
    )
  } else {
    return (
      <>
        <div className="disconnect-modal" ref={modalRef} onClick={(e) => e.stopPropagation()}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <div className="add-bar">
              <p>{formatPublicKeyDisplay(address)}</p>
              <button onClick={handleButtonClick}>
                <img src={copy} alt="copy" />
              </button>
            </div>
            <hr className="hr" />
            <div className="disconnect">
              <button className="sp-btn full-w tertiary" onClick={disconnect}>
                <p className="pri">Disconnect</p>
              </button>
            </div>
            <div className="links">
              <a
                href="https://www.concordia.systems/"
                className="a-link grey"
                rel="noopener noreferrer"
                target="_blank">
                Concordia
              </a>
              <a
                href="https://www.superposition.finance/"
                className="a-link grey"
                rel="noopener noreferrer"
                target="_blank">
                Superposition
              </a>
            </div>
          </div>
        </div>
        <div className="close-helper" onClick={closeModal} />
      </>
    )
  }
}

export default WalletDisconnect
