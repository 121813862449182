import usdtLogo from 'assets/logos/usdt.svg'

export function USDTRedemptionBanner() {
  return (
    <div className="usdt-redemption-banner">
      <a
        href="https://app.panora.exchange/swap/aptos?pair=lzUSDT-USDt"
        target="_blank"
        rel="noreferrer">
        <div className="usdt-redemption-banner__left">
          <img src={usdtLogo} alt="USDt" />
          <div className="usdt-redemption-banner__content">
            <h3>Native USDt redemption is live on Aptos!</h3>
            <p>Get some USDt with 1 to 1 zUSDT redemption while supplies last</p>
          </div>
        </div>
      </a>
    </div>
  )
}
